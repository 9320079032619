import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

let container: HTMLElement;

export function render(component: React.ReactNode): void {
    if (!container) {
        container = document.getElementById('root') as HTMLElement;
        const root = createRoot(container!);
        root.render(<StrictMode>{component}</StrictMode>);
    }
}
