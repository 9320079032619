import { RenderManualFieldExtensionConfigScreenCtx } from "datocms-plugin-sdk";
import { Canvas, FieldGroup, Form, SelectField, SwitchField, TextField } from "datocms-react-ui";
import { Parameters } from "../types/Parameters";
import models from "../data/models";
import { useCallback, useState } from "react";

type PropTypes = {
    ctx: RenderManualFieldExtensionConfigScreenCtx;
};

export default function HofmanMultiSelectConfig({ ctx }: PropTypes) {
    const [formValues, setFormValues] = useState<Partial<Parameters>>(ctx.parameters);
    const errors = ctx.errors as Partial<Parameters>;

    const update = useCallback(async (field: any, value: any) => {
        const newParameters = { ...formValues, [field]: value };
        setFormValues(newParameters);
        await ctx.setParameters(newParameters);
    }, [formValues, setFormValues, ctx]);

    return (
        <Canvas ctx={ctx}>
            <Form>
                <FieldGroup>
                    <SelectField
                        id="model"
                        required
                        name="model"
                        label="Model"
                        hint="Selecteer de modellen waaruit de opties komen"
                        selectInputProps={{
                            isMulti: true,
                            options: models,
                        }}
                        value={formValues.models}
                        error={errors.url}
                        onChange={update.bind(null, 'models')}
                    />
                </FieldGroup>
                <FieldGroup>
                    <SwitchField
                        id="includeResourceId"
                        name="includeResourceId"
                        label="Include resource ID"
                        value={formValues.includeResourceId || false}
                        onChange={update.bind(null, 'includeResourceId')}
                    />
                </FieldGroup>
                <FieldGroup>
                    <SwitchField
                        id="multiple"
                        name="multiple"
                        label="Allow selecting multiple items"
                        value={formValues.multiple || false}
                        onChange={update.bind(null, 'multiple')}
                    />
                    {formValues.multiple && (
                        <TextField
                            id="maxItems"
                            name="maxItems"
                            textInputProps={{
                                type: 'number',
                            }}
                            label="Maximum items"
                            placeholder="No maximum"
                            hint="The maximum number of items that can be selected"
                            value={formValues.maxItems}
                            error={errors.maxItems}
                            onChange={update.bind(null, 'maxItems')}
                        />
                    )}
                </FieldGroup>
            </Form>
        </Canvas>
    )
}
