export enum FieldType {
    Boolean        = 'boolean',
    Color          = 'color',
    DateTime       = 'date_time',
    Date           = 'date',
    File           = 'file',
    Float          = 'float',
    Gallery        = 'gallery',
    Integer        = 'integer',
    JSON           = 'json',
    LatLong        = 'lat_lon',
    Link           = 'link',
    Links          = 'links',
    RichText       = 'rich_text',
    SEO            = 'seo',
    Slug           = 'slug',
    String         = 'string',
    StructuredText = 'structured_text',
    Text           = 'text',
    Video          = 'video',
}

export default FieldType;
