export enum Shops {
    VetAnimalCare     = 'VET',
    HollandAnimalCare = 'HOL',
    HofmanAnimalCare  = 'HOF',
    BoerenWinkel      = 'BW',
    AgriDrogist       = 'AGR',
}


export interface Shop {
    label: string;
    value: string;
}

export const shops: Shop[] = [
    { label: 'Vet Animal Care', value: Shops.VetAnimalCare },
    { label: 'Holland Animal Care', value: Shops.HollandAnimalCare },
    { label: 'Hofman Animal Care', value: Shops.HofmanAnimalCare },
    { label: 'Boerenwinkel', value: Shops.BoerenWinkel },
    { label: 'Agridrogist', value: Shops.AgriDrogist },
];

export default shops;
