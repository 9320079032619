import { RenderFieldExtensionCtx } from 'datocms-plugin-sdk';
import get from 'lodash/get';
import { AsyncSelectInput, Canvas, FieldGroup, Form, ButtonGroup, ButtonGroupButton } from 'datocms-react-ui';
import React, { useState } from 'react';
import { PluginParameters } from "../types/PluginParameters";
import { getEndpoint } from "../data/getEndpoint";
import { Parameters } from "../types/Parameters";
import { Model } from "../data/models";

type Props = {
    ctx: RenderFieldExtensionCtx
};

export default function HofmanMultiSelect({ ctx }: Props) {
    const currentValue = get(ctx.formValues, ctx.fieldPath);
    const [loading, setLoading] = useState(false);
    const { multiple = false, maxItems, models = [] } = ctx.parameters as Parameters;

    const [selectedItems, setSelectedItems] = useState(() => {
        if (!!currentValue) {
            const { selected = [] } = JSON.parse(currentValue as string) || {};
            return selected;
        }
        return multiple ? [] : null;
    });

    const [selectedModel, setSelectedModel] = useState(() => {
        const { model = {} } = JSON.parse(currentValue as string) || {};
        const [firstModel] = models as Model[];
        return !!model?.value ? model?.value : firstModel.value;
    });

    const fetchOptions = async (inputValue: string) => {
        try {
            setLoading(true);
            const { includeResourceId = false } = ctx.parameters as Parameters;
            const { apiToken, baseApiUrl, shopAbbreviation } = ctx.plugin.attributes.parameters as PluginParameters;
            const { id: siteId, attributes: { name: siteName, internal_domain: internalDomain } } = ctx.site;

            const queryParams = new URLSearchParams({
                ...(!!inputValue && { search: inputValue }),
            });
            const endpoint = getEndpoint(selectedModel, baseApiUrl);
            const response = await fetch(`${endpoint}?` + queryParams, {
                method : 'GET',
                mode   : 'cors',
                headers: new Headers({
                    'Authentication': apiToken,
                    'Content-Type'  : 'application/json',
                    'dato-site-id'  : siteId,
                    'dato-site-name': siteName,
                    'shop'          : shopAbbreviation.value,
                    ...(!!internalDomain && { 'dato-internal-domain': internalDomain }),
                    ...((includeResourceId && { 'dato-resource-id': ctx.parameters?.recordId as string })),
                }),
            });
            if (!response.ok) {
                throw new Error(`Could not fetch options: ${response.statusText}`);
            }
            const { data: fetchedOptions = [] } = await response?.json?.() ?? { data: [] };
            return fetchedOptions;
        } finally {
            setLoading(false);
        }
    };

    const selectModel = (model: Model) => {
        setSelectedModel(model.value);
        setSelectedItems([]);
    };

    const selectItems = async (items: any) => {
        await ctx.setFieldValue(ctx.fieldPath, JSON.stringify({ selected: items, model: selectedModel }));
        await setSelectedItems(items);
    }

    return <Canvas ctx={ctx}>
        <Form>
            {models.length > 1 && (
                <ButtonGroup>
                    {models.map((model) =>
                        <ButtonGroupButton
                            key={model.value}
                            selected={selectedModel === model.value}
                            onClick={() => selectModel(model)}
                        >
                            {model.label}
                        </ButtonGroupButton>
                    )}
                </ButtonGroup>
            )}
            <FieldGroup>
                <AsyncSelectInput
                    key={selectedModel}
                    id={ctx.fieldPath}
                    name={ctx.fieldPath}
                    isMulti={multiple as boolean}
                    value={selectedItems}
                    loadOptions={fetchOptions}
                    isLoading={loading}
                    isClearable={true}
                    isOptionDisabled={() =>
                        multiple && maxItems ? maxItems <= selectedItems.length : false
                    }
                    defaultOptions
                    onChange={selectItems}
                />
            </FieldGroup>
        </Form>
    </Canvas>
}
