import { RenderConfigScreenCtx } from 'datocms-plugin-sdk';
import { Canvas, Form, FieldGroup, TextField, Button, SelectField } from 'datocms-react-ui';
import { Form as FormHandler, Field } from 'react-final-form';
import shops from '../data/shops';
import { PluginParameters } from "../types/PluginParameters";
import './styles.module.css';

type Props = {
    ctx: RenderConfigScreenCtx;
};

type FirstInstallationParameters = {};

type Parameters = FirstInstallationParameters|PluginParameters;

export default function ConfigScreen({ ctx }: Props) {
    const parameters = ctx.plugin.attributes.parameters as Parameters;

    return (
        // @ts-ignore
        <Canvas ctx={ctx}>
            <FormHandler
                initialValues={parameters}
                validate={(values) => {
                    const errors: Record<string, string> = {};
                    if (!values.apiToken) {
                        errors.apiToken = 'API token is verplicht';
                    }
                    if (!values.baseApiUrl) {
                        errors.baseApiUrl = 'Base API URL is verplicht';
                    }
                    if (!values.shopAbbreviation?.value) {
                        errors.shopAbbreviation = 'Shop is verplicht';
                    }
                    return errors;
                }}
                onSubmit={async (values: any) => {
                    await ctx.updatePluginParameters(values);
                    await ctx.notice('De instellingen zijn opgeslagen');
                }}>
                {({ handleSubmit, submitting, dirty }) => (
                    <Form onSubmit={handleSubmit}>
                        <FieldGroup>
                            <Field name="shopAbbreviation">
                                {({ input, meta: { error } }) => (
                                    <SelectField
                                        id="shopAbbreviation"
                                        required
                                        label="Shop"
                                        hint="Shop waar de opties vandaan komen"
                                        selectInputProps={{
                                            options: shops,
                                        }}
                                        error={error}
                                        {...input}
                                    />
                                )}
                            </Field>
                            <Field name="baseApiUrl">
                                {({ input, meta: { error } }) => (
                                    <TextField
                                        required
                                        id="baseApiUrl"
                                        label="Base API URL"
                                        placeholder="Base API URL"
                                        hint="Base URL voor de API"
                                        textInputProps={{
                                            type: 'url',
                                        }}
                                        error={error}
                                        {...input}
                                    />
                                )}
                            </Field>
                            <Field name="apiToken">
                                {({ input, meta: { error } }) => (
                                    <TextField
                                        required
                                        id="apiToken"
                                        label="API Token"
                                        placeholder="API Token"
                                        hint="Authenticatie token voor de API"
                                        textInputProps={{
                                            type: process.env.NODE_ENV === 'development' ? 'text' : 'password',
                                        }}
                                        error={error}
                                        {...input}
                                    />
                                )}
                            </Field>
                        </FieldGroup>
                        <Button
                            type="submit"
                            fullWidth
                            buttonSize="l"
                            buttonType="primary"
                            disabled={submitting || !dirty}>
                            Instellingen opslaan
                        </Button>
                    </Form>
                )}
            </FormHandler>
        </Canvas>
    );
}

