import { connect, IntentCtx, RenderManualFieldExtensionConfigScreenCtx, } from 'datocms-plugin-sdk';
import { render } from './utils/render';
import ConfigScreen from './entrypoints/ConfigScreen';
import 'datocms-react-ui/styles.css';
import HofmanMultiSelect from './entrypoints/HofmanMultiSelect';
import HofmanMultiSelectConfig from './entrypoints/HofmanMultiSelectConfig';
import { FieldType, FieldExtentionId, FieldExtensionType } from "./enums";
import React from 'react';
import ReactDOM from "react-dom";

connect({
    manualFieldExtensions(ctx: IntentCtx) {
        return [
            {
                id          : FieldExtentionId.MultiSelect,
                name        : 'Hofman Multiselect' + (process.env.NODE_ENV === 'development' ? ' (dev)' : ''),
                description : 'Custom plugin to select values from a list of options provided by the API',
                type        : FieldExtensionType.Editor,
                configurable: true,
                fieldTypes  : [FieldType.JSON],
            },
        ];
    },
    renderManualFieldExtensionConfigScreen(fieldExtensionId: string, ctx: RenderManualFieldExtensionConfigScreenCtx) {
        ReactDOM.render(
            <React.StrictMode>
                <HofmanMultiSelectConfig ctx={ctx} />
            </React.StrictMode>,
            document.getElementById('root'),
        );
    },
    renderConfigScreen(ctx) {
        return render(<ConfigScreen ctx={ctx} />);
    },
    renderFieldExtension(fieldExtensionId, ctx) {
        switch (fieldExtensionId) {
            case FieldExtentionId.MultiSelect:
                return render(<HofmanMultiSelect ctx={ctx} />);
        }
    },
});

