export enum Models {
    Variant          = 'variant',
    HomepageCategory = 'homepage-category',
    SubCategory      = 'sub-category',
    Brand            = 'brand',
    Shop             = 'shop',
    Blog             = 'blog',
};

export interface Model {
    label: string
    value: string
}

export const models: Model[] = [
    {
        label: 'Variant',
        value: Models.Variant,
    },
    {
        label: 'Homepage categorie',
        value: Models.HomepageCategory,
    },
    {
        label: 'Sub-categorie',
        value: Models.SubCategory,
    },
    {
        label: 'Merk',
        value: Models.Brand,
    },
    {
        label: 'Shop',
        value: Models.Shop,
    },
    {
        label: 'Blog',
        value: Models.Blog,
    },
];


export default models;
