import { Models } from "./models";

export const getEndpoint = (model: string, baseUrl: string) => {
    if (!baseUrl) {
        throw new Error('No base url provided');
    }

    const url = new URL(baseUrl);

    switch (model) {
        case Models.Variant:
            return new URL('plugin/dato/variants', url).toString();
        case Models.HomepageCategory:
            return new URL('plugin/dato/homepage-categories', url).toString();
        case Models.SubCategory:
            return new URL('plugin/dato/sub-categories', url).toString();
        case Models.Brand:
            return new URL('plugin/dato/brands', url).toString();
        case Models.Shop:
            return new URL('plugin/dato/shops', url).toString();
        case Models.Blog:
            return new URL('plugin/dato/blogs', url).toString();
        default:
            throw new Error(`No endpoint found for model: ${model}`);
    }
};
